/**
***  Burger Menu Toggle
***  @update 2024/09/24
**/

(() => {

  const burger = document.getElementById('burger'),
        close = document.getElementById('closeNavigation'),
        menu  = document.getElementById('pageNavigation'),
        ARIA_PRESSED = 'aria-pressed',
        ARIA_DISABLED  = 'aria-disabled';
  burger.onclick = (e) => {
    // burger が押されているかを確認します
    const isPressed = burger.getAttribute(ARIA_PRESSED) === 'true';
    // burger の aria-pressed を反対の状態に変更します
    burger.setAttribute(ARIA_PRESSED, !isPressed);
    // メニューの表示を切り替えます
    if(!isPressed) {
      menu.setAttribute(ARIA_DISABLED, 'false');
      document.body.style.overflow = 'hidden';
    };
  };

  // ナビゲーションを閉じる
  close.onclick = () => {
    burger.setAttribute(ARIA_PRESSED, 'false');
    menu.setAttribute(ARIA_DISABLED, 'true');
    document.body.style.overflow = 'auto';
  };

})();
