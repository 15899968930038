/**
***  ローディング画面の切り替え  */

window.loadingToggle = {
  loadingWrapper: document.getElementById('loadingWrapper'),
  ARIA_HIDDEN: 'aria-hidden',

  // ローディング画面をアクティブ
  active: function() {
    this.loadingWrapper.setAttribute(this.ARIA_HIDDEN, 'false');
    return;
  },

  // ローディング画面を非アクティブ
  inActive: function() {
    this.loadingWrapper.setAttribute(this.ARIA_HIDDEN, 'true');
    return;
  },
};



(function(){
  // ない場合はなにもしない
  if( ! loadingToggle.loadingWrapper ) return;
  setTimeout(function(){loadingToggle.inActive()}, 800);
}());
