(function(){
  const pagetop = document.getElementById('jsPagetop');

  // ない場合はなにもしない
  if( ! pagetop ) return;

  // Scroll event
  window.addEventListener('scroll', (e) => {
    if (window.scrollY > 100) {
      pagetop.setAttribute('aria-disabled', 'false');
    } else {
      pagetop.setAttribute('aria-disabled', 'true');
      pagetop.setAttribute('aria-pressed', 'false');
    }
  });

  // Click event
  pagetop.onclick = () => {
    pagetop.setAttribute('aria-pressed', 'true');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
}());
